import Header from "../components/header/v1/Header";
import Banner from "../sections/banner/v1/Banner";

const HomeV1 = () => {
  return (
    <div className="">
      <Header />
      <Banner />
    </div>
  );
};

export default HomeV1;
