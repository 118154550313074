// eslint-disable-next-line no-unused-vars
import React, { useEffect, useState } from "react";
import Button from "./Button";
import ModalWrapper from "../modal/Modal.style";
import { CgClose } from "react-icons/cg";
import { FcGoogle } from "react-icons/fc";
import {
  Web3AuthMPCCoreKit,
  WEB3AUTH_NETWORK,
  COREKIT_STATUS,
  SubVerifierDetailsParams,
} from "@web3auth/mpc-core-kit";
import { zeroAddress } from "viem";
import { FiChevronDown } from "react-icons/fi";
import { LucideCopy } from "lucide-react";
import Accesscode from "../accesscode/Accesscode";
import clipboardCopy from "clipboard-copy";
import { MdDone } from "react-icons/md";
import Tokenbalance from "../tokenbalance/Tokenbalance";
//import ethcontractABI from "../../abi/ethtransferabi.json";
import {
  ECDSAProvider,
  getRPCProviderOwner,
  SessionKeyProvider,
  getPermissionFromABI,
} from "@zerodev/sdk";
import { useWeb3AuthSigner } from "../context/web3-auth-signer";
import { SafeEventEmitterProvider } from "@web3auth/base";
import Web3 from "web3";
//import { BN } from "bn.js";
//import useWallet from "../hooks/use-wallet";
import { LocalAccountSigner } from "@alchemy/aa-core";
import { generatePrivateKey } from "viem/accounts";
//import { env } from "process";
import axios from "axios";
import Withdrawtoken from "../withdrawtoken/Withdrawtoken";
import { BN } from "bn.js";
import { ARBabi } from "../../abi/ARBabi";
import { BLOKCAbi } from "../../abi/BLOKCabi";
import { UsdcABI } from "../../abi/usdcabi";

interface UserData {
  accessToken: string;
  authuser: string;
  email: string;
  expires_in: string;
  idToken: string;
  name: string;
  profileImage: string;
  prompt: string;
  scope: string;
  state: {
    instanceId: string;
    verifier: string;
    typeOfLogin: string;
    redirectToOpener: boolean;
  };
  token_type: string;
  typeOfLogin: string;
  verifier: string;
  verifierId: string;
  version_info: string;
}
const selectedNetwork = WEB3AUTH_NETWORK.MAINNET;
const clientidweb3 = process.env.REACT_APP_WEB3AUTH_CLIENTID;

const coreKitInstance = new Web3AuthMPCCoreKit({
  web3AuthClientId: clientidweb3 as string,
  web3AuthNetwork: selectedNetwork,
  uxMode: "redirect",
  chainConfig: {
    chainNamespace: "eip155",
    chainId: "0xA4B1", // hex of 42161
    //rpcTarget: "https://rpc.ankr.com/arbitrum",
    rpcTarget:
      "https://arbitrum-mainnet.infura.io/v3/d54b5cf96b554b939c26255f6220a872",
    // Avoid using public rpcTarget in production.
    // Use services like Infura, Quicknode etc
    displayName: "Arbitrum Mainnet",
    blockExplorer: "https://arbiscan.io",
    ticker: "AETH",
    tickerName: "AETH",
  },
});

const ConnectWalletButton: React.FC = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isLoading1, setIsLoading1] = useState<boolean>(true);
  const [rewardtoken, setRewardtoken] = useState<boolean>(false);
  const [requiredshares, setRequiredshares] = useState<boolean>(false);
  const code1 = localStorage.getItem("code");
  const {
    setWeb3AuthSigner,
    web3AuthSigner,
    accountAddress,
    setAccountAddress,
    setSessionKeyProvider,
    setUserinfo,
    userinfo,
    setEcdsaProvider,
    code,
    setCode,
    setIsConnected,
    openModule,
    setOpenModule,
    setAccesscodeopen,
    accesscodeopen,
    setSessionethProvider,
    allowwithdraw,
  } = useWeb3AuthSigner();
  //console.log("🚀 ~ allowwithdraw:", allowwithdraw?.[0]?.user?.blockWithdraw);

  const [providercorkit, setProvidercorkit] =
    useState<SafeEventEmitterProvider | null>(null);
  const [openprofile, setOpenprofile] = useState<boolean>(false);
  const [backupFactorKey, setBackupFactorKey] = useState<any>("");
  const [web3, setWeb3] = useState<any>(undefined);
  const [copy, setcopy] = useState<boolean>(false);
  const [isLoadingMFA, setIsLoadingMFA] = useState<boolean>(false);
  // console.log("accountAddress--->", accountAddress);
  // const [coreKitInstance, setCoreKitInstance] =
  //   useState<Web3AuthMPCCoreKit | null>(null);
  //const [coreKitStatus, setCoreKitStatus] = useState<COREKIT_STATUS>(
  //  COREKIT_STATUS.NOT_INITIALIZED
  //);
  //const [sucessfullogin, setsucessfullogin] = useState<boolean>(false);
  //const [resetaccount, setResetaccount] = useState<boolean>(false);
  const name = userinfo?.name;

  useEffect(() => {
    // Delay the setting of isLoading1 to false by 5000 milliseconds (5 seconds)
    const delayLoading = setTimeout(() => {
      setIsLoading1(false);
    }, 3000);

    // Clear the timeout to avoid unexpected behavior if the component unmounts
    return () => clearTimeout(delayLoading);
  }, []);

  useEffect(() => {
    if (coreKitInstance) {
      try {
        const init = async () => {
          // add try catch here
          await coreKitInstance.init();

          if (coreKitInstance.provider) {
            setWeb3AuthSigner(coreKitInstance.provider);
          }

          //setCoreKitStatus(coreKitInstance.status);
          if (coreKitInstance.status === COREKIT_STATUS.REQUIRED_SHARE) {
            console.log(
              "coreKitInstance postboxKey ---->",
              coreKitInstance?.state
            );
            if (
              coreKitInstance?.state?.userInfo?.email ===
                "sheetal.nehra@gmail.com" ||
              coreKitInstance?.state?.userInfo?.email ===
                "frontend.tanthetaa@gmail.com"
            ) {
              setRequiredshares(true);
            }
          }
        };
        init();
      } catch (e) {
        console.log("coreKitInstance postboxKey ---->", coreKitInstance?.state);
      }
    }
  }, [setWeb3AuthSigner]);

  useEffect(() => {
    if (web3AuthSigner) {
      const userdata: UserData =
        coreKitInstance?.getUserInfo() as unknown as UserData;
      setUserinfo(userdata);
    }
  }, [setUserinfo, web3AuthSigner]);

  useEffect(() => {
    if (web3AuthSigner) {
      const web3 = new Web3(web3AuthSigner as any);
      setWeb3(web3);
    }
  }, [web3AuthSigner]);

  const login = async () => {
    try {
      setIsLoading(true);
      if (!coreKitInstance) {
        throw new Error("initiated to login");
      }
      // console.log("1");
      const verifierConfig = {
        subVerifierDetails: {
          typeOfLogin: "google",
          verifier: "blok-capital",
          clientId: process.env.REACT_APP_GOOGLE_ID,
        },
      } as SubVerifierDetailsParams;

      await coreKitInstance.loginWithOauth(verifierConfig);

      if (coreKitInstance.status === COREKIT_STATUS.REQUIRED_SHARE) {
        console.log(
          "required more shares, please enter your backup/ device factor key, or reset account unrecoverable once reset, please use it with caution]"
        );
        //setResetaccount(true);
      }

      if (coreKitInstance.provider) {
        setProvidercorkit(coreKitInstance.provider);
        setWeb3AuthSigner(coreKitInstance.provider);
      }
      //setCoreKitInstance(coreKitInstance);
      //google - tkey - w3a
      //new-blokc-verifier //

      //setOpenModuleGoogle(true);
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-call

      //const userdata: UserData =
      //  coreKitInstance.getUserInfo() as unknown as UserData;
      //setUserinfo(userdata);

      //localStorage.setItem("userRole", selectedOption);

      //setIsConnected(true);

      setOpenprofile(true);
      //router.push(Routes.wallet.root);
    } catch (error) {
      console.log(error);
      //setResetaccount(true);
    } finally {
      setIsLoading(false);
      console.log("coreKitInstance postboxKey ---->", coreKitInstance?.state);
    }
  };

  useEffect(() => {
    if (web3) {
      const getChainID = async () => {
        if (!web3) {
          console.log("web3 not initialized yet");
          return;
        }
        const chainId = await web3.eth.getChainId();
        //console.l og("chainid--->", chainId);
        return chainId;
      };
      getChainID();
    }
  }, [web3]);

  const logout = async () => {
    // console.log("---------------");

    if (!coreKitInstance) {
      throw new Error("coreKitInstance not found");
    }
    await coreKitInstance.logout();
    localStorage.clear();
    setWeb3AuthSigner(undefined);
    setOpenModule(false);

    setAccountAddress(undefined);
    setCode(undefined);
  };

  const popupopen = () => {
    if (code === undefined) {
      setAccesscodeopen(true);
    } else {
      setOpenModule(true);
    }
  };

  const close1 = () => {
    setOpenModule(false);
    setIsLoading(false);
  };

  const close = () => {
    // console.log("close-----------------");
    setOpenprofile(false);
  };

  const finalsubmit = () => {
    setOpenModule(false);
    setIsConnected(true);
  };

  const notify = () => {
    if (accountAddress) {
      void clipboardCopy(accountAddress);
      setcopy(true);
      setTimeout(() => {
        setcopy(false);
      }, 100);
    }
  };

  const openAccount = () => {
    setOpenprofile(true);
  };

  const rewardtokens = () => {
    setRewardtoken(true);
    setOpenprofile(false);
  };

  useEffect(() => {
    if (web3AuthSigner) {
      const sessionKey = LocalAccountSigner.privateKeyToAccountSigner(generatePrivateKey());
      const contractAddress = "0xaf88d065e77c8cC2239327C5EDb3A432268e5831"; //usdc contract
      // const contractAddress = "0xBC4d9D3Dfe6AB1D36Ede90050ce96FCB937469f0";

      const ecdcfunction = async () => {
        if (web3AuthSigner) {
          const ecdsaProvider = await ECDSAProvider.init({
            projectId: process.env.REACT_APP_ZERODEV_PROJECT_ID as string,
            owner: getRPCProviderOwner(web3AuthSigner),
          });
          const address = await ecdsaProvider.getAddress();
          //console.log("address-->", address);

          setAccountAddress(address);
          setEcdsaProvider(ecdsaProvider);

          const sessionKeyProvider = await SessionKeyProvider.init({
            // ZeroDev project ID
            projectId: process.env.REACT_APP_ZERODEV_PROJECT_ID as string,
            bundlerProvider: "PIMLICO",
            // The master signer
            defaultProvider: ecdsaProvider,
            // the session key (private key)
            opts: {
              paymasterConfig: {
                onlySendSponsoredTransaction: true,
                policy: "VERIFYING_PAYMASTER",
              },
            },
            sessionKey,
            // session key parameters
            sessionKeyData: {
              // The UNIX timestamp at which the session key becomes valid
              validAfter: 0,
              // The UNIX timestamp at which the session key becomes invalid
              validUntil: 0,
              // The permissions
              // Each permission can be considered a "rule" for interacting with a particular
              // contract/function.  To create a key that can interact with multiple
              // contracts/functions, set up one permission for each.

              permissions: [
                getPermissionFromABI({
                  // Target contract to interact with
                  target: contractAddress,
                  // Maximum value that can be transferred.  In this case we
                  // set it to zero so that no value transfer is possible.
                  valueLimit: BigInt(0),
                  // Contract abi
                  abi: UsdcABI,
                  // abi: BLOKCAbi,
                  // Function name
                  functionName: "transfer",
                  // An array of conditions, each corresponding to an argument for
                  // the function.
                  args: [
                    null,
                    // {
                    //   // Argument operator and value.
                    //   operator: ParamOperator.EQUAL,
                    //   value: address,
                    // },
                    null,
                  ],
                }),
                // {
                //   // Target contract to interact with
                //   target: contractAddress,
                //   // Maximum value that can be transferred.  In this case we
                //   // set it to zero so that no value transfer is possible.
                //   valueLimit: BigInt(0),
                //   // The function (as specified with a selector) that can be called on

                //   sig: getFunctionSelector("transfer(_to,_value)"),
                //   // Whether you'd like to call this function via CALL or DELEGATECALL.
                //   // DELEGATECALL is dangerous -- don't use it unless you know what you
                //   // are doing.
                //   // Each "rule" is a condition on a parameter.  In this case, we only
                //   // allow for minting NFTs to our own account.
                //   rules: [
                //     {
                //       // The condition in this case is "EQUAL"
                //       condition: ParamCondition.EQUAL,
                //       // The offset of the parameter is 0 since it's the first parameter.
                //       // We will simplify this later.
                //       offset: 0,
                //       // We pad the address to be the correct size.
                //       // We will simplify this later.
                //       param: pad(address, { size: 32 }),
                //     },
                //   ],
                // },
              ],
              // The "paymaster" param specifies whether the session key needs to
              // be used with a specific paymaster.
              // Without it, the holder of the session key can drain ETH from the
              // account by spamming transactions and wasting gas, so it's recommended
              // that you specify a trusted paymaster.
              //
              // address(0) means it's going to work with or without paymaster
              // address(1) works only with paymaster
              // address(paymaster) works only with the specified paymaster
              paymaster: zeroAddress,
            },
          });

          // const sessionKeyProvider1 = await SessionKeyProvider.init({
          //   // ZeroDev project ID
          //   projectId: process.env.REACT_APP_ZERODEV_PROJECT_ID as string,
          //   //bundlerProvider: "PIMLICO",
          //   // The master signer
          //   defaultProvider: ecdsaProvider,
          //   // the session key (private key)
          //   opts: {
          //     paymasterConfig: {
          //       onlySendSponsoredTransaction: true,
          //       policy: "VERIFYING_PAYMASTER",
          //     },
          //   },
          //   sessionKey,
          //   // session key parameters
          //   sessionKeyData: {
          //     // The UNIX timestamp at which the session key becomes valid
          //     validAfter: 0,
          //     // The UNIX timestamp at which the session key becomes invalid
          //     validUntil: 0,
          //     // The permissions
          //     // Each permission can be considered a "rule" for interacting with a particular
          //     // contract/function.  To create a key that can interact with multiple
          //     // contracts/functions, set up one permission for each.

          //     permissions: [
          //       getPermissionFromABI({
          //         // Target contract to interact with
          //         target: contractAddressBLOKC,
          //         // Maximum value that can be transferred.  In this case we
          //         // set it to zero so that no value transfer is possible.
          //         valueLimit: BigInt(0),
          //         // Contract abi
          //         //abi: contractABI,
          //         abi: UsdcABI,
          //         // Function name
          //         functionName: "transfer",
          //         // An array of conditions, each corresponding to an argument for
          //         // the function.
          //         args: [
          //           null,
          //           // {
          //           //   // Argument operator and value.
          //           //   operator: ParamOperator.EQUAL,
          //           //   value: address,
          //           // },
          //           null,
          //         ],
          //       }),
          //       // {
          //       //   // Target contract to interact with
          //       //   target: contractAddress,
          //       //   // Maximum value that can be transferred.  In this case we
          //       //   // set it to zero so that no value transfer is possible.
          //       //   valueLimit: BigInt(0),
          //       //   // The function (as specified with a selector) that can be called on

          //       //   sig: getFunctionSelector("transfer(_to,_value)"),
          //       //   // Whether you'd like to call this function via CALL or DELEGATECALL.
          //       //   // DELEGATECALL is dangerous -- don't use it unless you know what you
          //       //   // are doing.
          //       //   // Each "rule" is a condition on a parameter.  In this case, we only
          //       //   // allow for minting NFTs to our own account.
          //       //   rules: [
          //       //     {
          //       //       // The condition in this case is "EQUAL"
          //       //       condition: ParamCondition.EQUAL,
          //       //       // The offset of the parameter is 0 since it's the first parameter.
          //       //       // We will simplify this later.
          //       //       offset: 0,
          //       //       // We pad the address to be the correct size.
          //       //       // We will simplify this later.
          //       //       param: pad(address, { size: 32 }),
          //       //     },
          //       //   ],
          //       // },
          //     ],
          //     // The "paymaster" param specifies whether the session key needs to
          //     // be used with a specific paymaster.
          //     // Without it, the holder of the session key can drain ETH from the
          //     // account by spamming transactions and wasting gas, so it's recommended
          //     // that you specify a trusted paymaster.
          //     //
          //     // address(0) means it's going to work with or without paymaster
          //     // address(1) works only with paymaster
          //     // address(paymaster) works only with the specified paymaster
          //     paymaster: zeroAddress,
          //   },
          // });
          //const usePaymaster = true;
          //const useTokenPaymaster = false;

          //const sessionethProvider = await SessionKeyProvider.init({
          //  projectId: process.env.REACT_APP_ZERODEV_PROJECT_ID as string,
          //  defaultProvider: ecdsaProvider,
          //  sessionKey,
          //  sessionKeyData: {
          //    validAfter: 0,
          //    validUntil: 0,
          //    permissions: [],
          //    paymaster: zeroAddress,
          //  },
          //  usePaymaster,
          //  opts: {
          //    providerConfig: {
          //      opts: {
          //        txMaxRetries: 10,
          //        txRetryIntervalMs: 2000,
          //      },
          //    },
          //    paymasterConfig: useTokenPaymaster
          //      ? {
          //          policy: "TOKEN_PAYMASTER",
          //          gasToken: "TEST_ERC20",
          //        }
          //      : {
          //          policy: "VERIFYING_PAYMASTER",
          //        },
          //  },
          //});

          setSessionKeyProvider(sessionKeyProvider);
          // setSessionethProvider(sessionKeyProvider1);
          //console.log("sessionKeyProvider------>", sessionKeyProvider);
        }

        // console.log("sessionKeyProvider", sessionKeyProvider);
      };
      ecdcfunction();
    }
  }, [
    setAccountAddress,
    setEcdsaProvider,
    setSessionKeyProvider,
    setSessionethProvider,
    web3AuthSigner,
  ]);

  useEffect(() => {
    if (accountAddress && code1) {
      const sendApiRequest = async () => {
        const dataToSend = {
          wallet: accountAddress,
          email: userinfo?.email,
          userName: userinfo?.name,
          accessCode: code1,
        };
        //console.log("dataToSend--->", dataToSend);
        try {
          await axios
            .post(`https://core.blokcapital.io/presaleRegister`, dataToSend)
            .then((response) => {
              // console.log("API Response:", response);
              //console.log("message-->", response);
            });
        } catch (error) {
          console.error("API Error:", error);
        }
      };
      sendApiRequest();
    }
  }, [code1, accountAddress, userinfo?.email, userinfo?.name]);

  const inputBackupFactorKey = async () => {
    console.log("input");
    if (!coreKitInstance) {
      throw new Error("coreKitInstance not found");
    }
    if (!backupFactorKey) {
      throw new Error("backupFactorKey not found");
    }
    setIsLoadingMFA(true);
    try {
      const factorKey = new BN(backupFactorKey, "hex");
      await coreKitInstance.inputFactorKey(factorKey);

      if (coreKitInstance.status === COREKIT_STATUS.REQUIRED_SHARE) {
        console.log(
          "required more shares even after inputing backup factor key"
        );
      }

      if (coreKitInstance.provider) {
        const userdata = coreKitInstance?.getUserInfo();
        setUserinfo(userdata as any);
        setIsLoading(true);
        setProvidercorkit(coreKitInstance.provider);
        setWeb3AuthSigner(coreKitInstance.provider);
        setRequiredshares(false);
        setIsLoadingMFA(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      {web3AuthSigner && userinfo ? (
        <>
          <Button
            // walletAddress
            className="connect-wallet-btn"
            variant={"connect"}
            onClick={openAccount}
          >
            <p className="md:block hidden">
              {/*{accountAddress
                ? accountAddress.slice(0, 3) + "...." + accountAddress.slice(-3)
                : ""}*/}
              Hi, {name ? name.slice(0, 7) : ""}
            </p>
            <p className="md:hidden block">
              {/*{accountAddress
                ? accountAddress.slice(0, 3) + "...." + accountAddress.slice(-3)
                : ""}*/}
              Hi
            </p>
            {/*<span className="short-address">{accountAddress}</span>*/}
            <FiChevronDown />
          </Button>

          {openprofile && (
            <ModalWrapper className="gittu-modal">
              <div className="overlay"></div>
              <div className="gittu-modal-content">
                <div className="gittu-modal-header">
                  <div>{""}</div>
                  <div onClick={close} role="button">
                    <CgClose className="" size={20} />
                  </div>
                </div>
                <div className=" ">
                  <div className="flex flex-col">
                    <div className="flex">
                      <p className="text-center">
                        Hi {userinfo?.name}, your blockchain smart wallet
                        account with Address:
                      </p>
                    </div>
                    <div className="flex justify-center items-center gap-2">
                      <h5 className="">
                        {accountAddress
                          ? accountAddress.slice(0, 5) +
                            "...." +
                            accountAddress.slice(-5)
                          : null}
                      </h5>
                      <button onClick={notify} className="">
                        {copy ? <MdDone size={15} /> : <LucideCopy size={15} />}
                      </button>
                    </div>
                    <div className="mb-3 ">
                      <Tokenbalance />
                    </div>
                    <div>
                      <div className="space-y-2">
                        {allowwithdraw ? (
                          allowwithdraw[0].user?.blockWithdraw ===
                          "Unblocked" ? (
                            <div className="md:flex md:justify-center md:items-center md:gap-2 md:space-y-0  space-y-2 w-full">
                              <a
                                href={`https://arbiscan.io/address/${accountAddress}`}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="d-flex flex-column justify-content-center "
                              >
                                <Button
                                  className="connect-wallet-btn"
                                  variant={"connect"}
                                  type="submit"
                                >
                                  View on Arbiscan Link
                                </Button>
                              </a>

                              <div
                                onClick={rewardtokens}
                                className="d-flex flex-column justify-content-center"
                              >
                                <Button variant={"connect"} role="button">
                                  withdraw token
                                </Button>
                              </div>
                            </div>
                          ) : (
                            <a
                              href={`https://arbiscan.io/address/${accountAddress}`}
                              target="_blank"
                              rel="noopener noreferrer"
                              className="d-flex flex-column justify-content-center "
                            >
                              <Button
                                className="connect-wallet-btn"
                                variant={"connect"}
                                type="submit"
                              >
                                View on Arbiscan Link
                              </Button>
                            </a>
                          )
                        ) : null}
                        {/* <a
                          href={`https://arbiscan.io/address/${accountAddress}`}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="d-flex flex-column justify-content-center "
                        >
                          <Button
                            className="connect-wallet-btn"
                            variant={"connect"}
                            type="submit"
                          >
                            View on Arbiscan Link
                          </Button>
                        </a> */}
                        <div
                          onClick={logout}
                          className="d-flex flex-column justify-content-center"
                        >
                          <Button variant={"connect"} role="button">
                            LogOut
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </ModalWrapper>
          )}
        </>
      ) : (
        <>
          <div onClick={() => !isLoading1 && popupopen()}>
            <Button className="connect-wallet-btn" variant={"connect"}>
              {isLoading1 ? (
                <div className="spinner-border h-6 w-6" role="status">
                  <span className="sr-only"></span>
                </div>
              ) : (
                "Login"
              )}
            </Button>
          </div>
          {openModule && (
            <ModalWrapper className="gittu-modal">
              <div className="overlay"></div>
              <div className="gittu-modal-content">
                {accountAddress ? null : (
                  <>
                    <div className="gittu-modal-header">
                      <div>{""}</div>
                      <div onClick={() => close1()} role="button">
                        <CgClose className="" size={20} />
                      </div>
                    </div>
                    <div className="mx-auto text-center">
                      <p className="mb-3">
                        Thank you for entering the Private Sale Zone, now login
                        with your gmail to whitelist yourself for the sale and
                        create your onchain smart wallet account.
                      </p>

                      <h4
                        onClick={() => login()}
                        className="d-flex flex-column justify-content-center"
                        role="button"
                      >
                        <Button
                          className="connect-wallet-btn"
                          variant={"connect"}
                        >
                          {isLoading && (
                            <div className="">
                              <div
                                className="spinner-border h-5 w-5"
                                role="status"
                              >
                                <span className="sr-only"></span>
                              </div>
                            </div>
                          )}
                          <div
                            className={` flex gap-2 justify-center items-center text-center ${
                              isLoading ? "disabled" : " "
                            }`}
                          >
                            <FcGoogle size={28} />
                            Login with Google
                          </div>
                        </Button>
                      </h4>

                      <div className="d-flex flex-column justify-content-center">
                        {isLoading && !accountAddress && (
                          <p className="text-warning mx-auto mt-3">
                            Please Wait...{" "}
                          </p>
                        )}
                      </div>
                    </div>
                  </>
                )}
                {/*<div className="d-flex flex-column justify-content-center">
                  {sucessfullogin && accountAddress && (
                    <div className="flex flex-column">
                      <div className="">
                        <div className="text-center">
                          <h5 className="mb-3 ">
                            Congratulations {userinfo?.name}, you have just
                            created your blockchain smart wallet account with
                            Address:{" "}
                            {accountAddress
                              ? accountAddress.slice(0, 5) +
                                "...." +
                                accountAddress.slice(-5)
                              : null}
                            <button
                              onClick={notify}
                              className="mx-2 !bg-transparent rounded-5"
                            >
                              {copy ? (
                                <MdDone size={15} />
                              ) : (
                                <LucideCopy size={15} />
                              )}
                            </button>
                          </h5>

                          <div className="d-grid gap-2 mt-3">
                            <a
                              href={`https://etherscan.io/address/${accountAddress}`}
                              target="_blank"
                              rel="noopener noreferrer"
                              className="d-flex flex-column justify-content-center"
                            >
                              <Button
                                className="connect-wallet-btn"
                                variant={"connect"}
                                type="submit"
                              >
                                <p className="mx-2">View on Etherscan Link</p>
                              </Button>
                            </a>
                            <div
                              className="d-flex flex-column justify-content-center"
                              onClick={finalsubmit}
                            >
                              <Button
                                className="connect-wallet-btn"
                                variant={"connect"}
                              >
                                <p className="mx-2">
                                  Continue to the Private Sale
                                </p>
                              </Button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>*/}
              </div>
            </ModalWrapper>
          )}
        </>
      )}
      {accesscodeopen && (
        <Accesscode
          setCode={setCode}
          setAccesscodeopen={setAccesscodeopen}
          setOpenModule={setOpenModule}
        />
      )}

      {rewardtoken && (
        <Withdrawtoken
          setRewardtoken={setRewardtoken}
          setOpenprofile={setOpenprofile}
        />
      )}

      {requiredshares && (
        <div>
          <ModalWrapper className="gittu-modal">
            <div className="overlay"></div>
            <div className="gittu-modal-content">
              <div className="flex flex-col justify-between gap-2 ">
                <div className="text-xl font-semibold text-center">
                  Recover your Account
                </div>
                <div className="flex flex-col gap-2 ">
                  <label className="font-semibold">
                    Backup/ Device factor key:
                  </label>
                  <input
                    type="text"
                    value={backupFactorKey}
                    onChange={(e) => setBackupFactorKey(e.target.value)}
                    className="bg-[#1c1c1c] rounded-md text-white p-1 border outline-none"
                  />
                  <button
                    onClick={() => inputBackupFactorKey()}
                    className={`flex justify-center px-3 py-1 rounded-lg cursor-pointer bg-transparent border ${isLoadingMFA}`}
                  >
                    {isLoadingMFA ? (
                      <svg
                        aria-hidden="true"
                        className="w-5 h-5 text-white animate-spin  fill-yellow-500"
                        viewBox="0 0 100 101"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                          fill="currentColor"
                        />
                        <path
                          d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                          fill="currentFill"
                        />
                      </svg>
                    ) : (
                      "Input Factor Key"
                    )}
                  </button>
                </div>
              </div>
            </div>
          </ModalWrapper>
        </div>
      )}
    </>
  );
};

export default ConnectWalletButton;
