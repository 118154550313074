/* eslint-disable @typescript-eslint/no-explicit-any */

/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import { type SafeEventEmitterProvider } from "@web3auth/base";
import axios from "axios";
import { createContext, useContext, useEffect, useState } from "react";
import React from "react";
import CryptoJS from "crypto-js";
export interface Wallet1 {
  email: string;
  name: string;
  profileImage: string;
  verifier: string;
  verifierId: string;
  typeOfLogin: string;
  accessToken: string;
  idToken: string;
  state: {
    instanceId: string;
    verifier: string;
    typeOfLogin: string;
    redirectToOpener: boolean;
  };
  token_type: string;
  expires_in: string;
  scope: string;
  authuser: string;
  prompt: string;
  version_info: string;
}
export interface Web3AuthSignerContext {
  web3AuthSigner?: SafeEventEmitterProvider;
  setWeb3AuthSigner: React.Dispatch<
    React.SetStateAction<SafeEventEmitterProvider | undefined>
  >;

  sessionKeyProvider: any;
  setSessionKeyProvider: any;

  sessionethProvider: any;
  setSessionethProvider: any;

  accountAddress?: `0x${string}`;
  setAccountAddress: React.Dispatch<
    React.SetStateAction<`0x${string}` | undefined>
  >;
  userinfo: Wallet1 | null;
  setUserinfo: React.Dispatch<React.SetStateAction<Wallet1 | null>>;

  ecdsaProvider: any | null;
  setEcdsaProvider: React.Dispatch<React.SetStateAction<any | null>>;
  // Correct variable name

  ethprice: number | null;
  setEthprice: React.Dispatch<React.SetStateAction<number | null>>;

  code: string | undefined;
  setCode: React.Dispatch<React.SetStateAction<string | undefined>>;

  isConnected: boolean;
  setIsConnected: React.Dispatch<React.SetStateAction<boolean>>;

  openModule: boolean;
  setOpenModule: React.Dispatch<React.SetStateAction<boolean>>;

  transakopen: boolean;
  setTransak: React.Dispatch<React.SetStateAction<boolean>>;

  accesscodeopen: boolean;
  setAccesscodeopen: React.Dispatch<React.SetStateAction<boolean>>;

  accesscode: number | null;
  setAccesscode: React.Dispatch<React.SetStateAction<number | null>>;

  usdcbalace: number | null;
  setusdcbalace: React.Dispatch<React.SetStateAction<number | null>>;

  blokcbalace: number | null;
  setBlokcbalace: React.Dispatch<React.SetStateAction<number | null>>;

  totalblokcbalance: number | null;
  settotalblokcbalance: React.Dispatch<React.SetStateAction<number | null>>;

  totaluser: any;
  setTotaluser: React.Dispatch<React.SetStateAction<any>>;

  allowwithdraw: any;
  setAllowwithdraw: React.Dispatch<React.SetStateAction<any>>;

  arbbalace: number | null;
  setArbbalace: React.Dispatch<React.SetStateAction<any | null>>;
  migratedata: number | null;
  setMigratedata: React.Dispatch<React.SetStateAction<any | null>>;
}

export const Web3AuthSigner = createContext<Web3AuthSignerContext | null>(null);

export const useWeb3AuthSigner = () => {
  const signer = useContext(Web3AuthSigner);
  if (signer === null) {
    throw new Error(
      "useWeb3AuthSigner() can only be used inside of <Web3AuthSignerProvider />, " +
        "please declare it at a higher level."
    );
  }
  return signer;
};

export function Web3AuthSignerProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const [web3AuthSigner, setWeb3AuthSigner] = useState<
    SafeEventEmitterProvider | undefined
  >(undefined);
  const [accountAddress, setAccountAddress] = useState<
    `0x${string}` | undefined
  >(undefined);
  const [sessionKeyProvider, setSessionKeyProvider] = useState<any>();
  const [sessionethProvider, setSessionethProvider] = useState<any>();
  const [userinfo, setUserinfo] = useState<Wallet1 | null>(null);

  const [ecdsaProvider, setEcdsaProvider] = useState<any | null>(null);

  const [ethprice, setEthprice] = useState<number | null>(null);
  const [code, setCode] = useState<string | undefined>(undefined);
  const [isConnected, setIsConnected] = useState<boolean>(false);
  const [openModule, setOpenModule] = useState<boolean>(false);
  const [accesscodeopen, setAccesscodeopen] = useState<boolean>(false);
  const [accesscode, setAccesscode] = useState<number | null>(null);
  const [usdcbalace, setusdcbalace] = useState<number | null>(null);
  const [blokcbalace, setBlokcbalace] = useState<number | null>(null);
  const [totalblokcbalance, settotalblokcbalance] = useState<number | null>(
    null
  );
  const [arbbalace, setArbbalace] = useState<number | null>(null);
  const [transakopen, setTransak] = useState<boolean>(false);
  const [totaluser, setTotaluser] = useState<any>();
  const [allowwithdraw, setAllowwithdraw] = useState<any>();
  const [migratedata, setMigratedata] = useState<any>();
  useEffect(() => {
    if (totaluser) {
      const finduserallow = async () => {
        const Userinfofind = await totaluser?.filter((user: any) => {
          return user?.user?.email === userinfo?.email;
        });
        setAllowwithdraw(Userinfofind);
      };
      finduserallow();
    }
    fetchData();
  }, [totaluser, userinfo?.email]);

  // const decryptionKey =  as string;

  // const decryptData = (encryptedData: string) => {

  //   try {
  //     // Decode the base64 encoded encrypted data
  //     const base8Data = CryptoJS.AES.decrypt(
  //       decryptionKey,
  //       encryptedData
  //     ).toString(CryptoJS.enc.Utf8);

  //     // Check if the decryption result is valid
  //     if (!base8Data) {
  //       throw new Error("Decryption resulted in empty string");
  //     }

  //     return base8Data;
  //   } catch (error) {
  //     console.error("Decryption error:", error);
  //     throw error; // Rethrow the error to be handled by the calling function
  //   }
  // };

  // const fetchData = async () => {
  //   try {
  //     const response = await axios.get(
  //       "http://localhost:8000/app/admin/showAllUser"
  //     );
  //     const encryptedData = response?.data?.encryptedData;
  //     console.log("🚀 ~ fetchData ~ encryptedData:", encryptedData)
  //     const decryptedData = JSON.parse(decryptData(encryptedData));
  //     console.log("🚀 ~ fetchData ~ decryptedData:", decryptedData);
  //     setMigratedata(encryptedData);
  //   } catch (error) {
  //     console.error("Error fetching or decrypting data:", error);
  //   }
  // };

  const fetchData = async () => {
    try {
      const response = await axios.get(
        "https://communitycore.blokcapital.io/app/admin/showAllUser"
      );

      const decryptedData = response?.data?.encryptedData;
      const bytes = CryptoJS.AES.decrypt(decryptedData, "BLOKC4337AA6551");
      const decryptedData1 = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
      // console.log("🚀 ~ fetchData ~ decryptedData1:", decryptedData1);
      setMigratedata(decryptedData1?.data);
    } catch (error) {
      console.error("Error fetching or decrypting data:", error);
    }
  };

  // useEffect(())
  return (
    <Web3AuthSigner.Provider
      value={{
        web3AuthSigner,
        setWeb3AuthSigner,
        accountAddress,
        setAccountAddress,
        sessionKeyProvider,
        setSessionKeyProvider,
        setUserinfo,
        userinfo,
        ecdsaProvider,
        setEcdsaProvider,
        ethprice,
        setEthprice,
        code,
        setCode,
        isConnected,
        setIsConnected,
        openModule,
        setOpenModule,
        accesscodeopen,
        setAccesscodeopen,
        accesscode,
        setAccesscode,
        usdcbalace,
        setusdcbalace,
        blokcbalace,
        setBlokcbalace,
        sessionethProvider,
        setSessionethProvider,
        setTransak,
        transakopen,
        totalblokcbalance,
        settotalblokcbalance,
        totaluser,
        setTotaluser,
        allowwithdraw,
        setAllowwithdraw,
        arbbalace,
        setArbbalace,
        migratedata,
        setMigratedata,
      }}
    >
      {children}
    </Web3AuthSigner.Provider>
  );
}
